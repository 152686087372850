import React from "react"
import PageHeader from "components/PageHeader"
import Seo from "components/Seo"
import SectionPrograms from "components/SectionPrograms"
import SectionWhyUs from "components/SectionWhyUs"
import SectionContactCta from "components/SectionContactCta"

const CASES = [
  {
    title: "직무분석/직무평가",
    description:
      "직무를 정의하고 HR제도에 필요한 직무정보를 체계적으로 수집합니다. 수집된 직무정보를 토대로 직무가치를 측정하여 평가합니다.",
  },

  {
    title: "조직성과관리 구축",
    description:
      "조직의 핵심성과를 측정할 수 있는 지표를 개발하고 개발한 성과지표를 모니터링할 수 있는 체계를 구축하여 조직성과에 대한 동기부여를 확보합니다.",
  },

  {
    title: "개인평가제도 설계",
    description:
      "개인의 성과·역량을 측정할 수 있는 지표를 개발하고, 해당 기업에 적합한 평가모형을 개발·적용하여 평가결과의 신뢰성을 강화합니다.",
  },

  {
    title: "저성과자관리체계 구축",
    description:
      "저성과자 선별기준을 정립하고 저성과자에 대한 직무재배치, 역량강화 및 안정적인 Outplacement 등 관리 Tool을 개발합니다.",
  },

  {
    title: "직급/승진체계 개선",
    description:
      "적정 수준의 직급 단계수를 정의하고, 직급 운영기준을 명확히 합니다. 이를 승진체계와 연계·개선하여 구성원의 상승동기를 확보합니다.",
  },

  {
    title: "보상제도 설계",
    description:
      "보상수준의 외부경쟁력을 측정하고, 기업의 보상철학 및 구성원의 니즈를 고려하여 기본급, 성과급 및 수당 등 세부 운영방안을 설계합니다",
  },

  {
    title: "임원보상제도 설계",
    description:
      "임원 기본급, 단기성과급 및 장기성과급 운영방안을 개발하여 임원 동기부여 및 Retention를 강화합니다.",
  },

  {
    title: "복리후생 설계",
    description:
      "복리후생의 외부경쟁력을 측정하고, 재원건전성 및 구성원 니즈를 고려하여 기업에 최적화된 복리후생 제도를 설계합니다.",
  },

  {
    title: "채용제도 설계",
    description:
      "기업에 적합한 인재를 선발할 수 있도록 기업의 채용전략을 개발하고, 인재유인에 적합한 모집채널 및 채용 Tool을 개발합니다.",
  },

  {
    title: "이동제도 설계",
    description:
      "효율적 인적자원배치를 위해 이동기준, 이동경로 및 이동제도 프로세스를 개발하고, 원활한 이동을 위한 지원체계를 구축합니다.",
  },

  {
    title: "Global HR체계 구축",
    description:
      "기업의 글로벌화에 따라 단계적으로 HR Governance를 정립하고, 해외인력을 효율적으로 관리하기 위한 인사관리체계를 구축합니다.",
  },

  {
    title: "직원몰입도 향상",
    description:
      "직원몰입도의 현 수준을 측정하고, 몰입도를 저해하는 주요 요인을 정의하여 몰입도 향상을 위한 개선과제 및 전략을 도출합니다.",
  },
]

export default function HrmProgramPage() {
  return (
    <div>
      <Seo
        title={"인적자원관리 (Human Resource Management)"}
        description="HRM은 조직, 전략, 문화, 구성원, 업무 프로세스 등 기업의 특성과
        정합하도록 설계되어야 합니다."
      />
      <PageHeader
        title={"인적자원관리 (HRM)"}
        description="HRM은 조직, 전략, 문화, 구성원, 업무 프로세스 등 기업의 특성과
        정합하도록 설계되어야 합니다."
      />
      <SectionPrograms source={CASES} />
      <SectionWhyUs />
      <SectionContactCta />

      {/* <div className="hidden">
      </div> */}
    </div>
  )
}
